import {changeValue} from '@github-ui/form-utils'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
import {onKey} from './github/onfocus'
import {validate} from './github/behaviors/html-validation'

onKey('keyup', '.js-password-confirm', validatePasswordConfirmation)

onKey('keyup', '.js-password-with-confirmation', () => {
  const passwordConfirmField = document.querySelector<HTMLInputElement>('.js-password-confirm')!
  if (passwordConfirmField.value !== '') {
    validatePasswordConfirmation()
  }
})

// Add suggested usernames to form
observe('.js-suggested-usernames-container', function (container) {
  const suggestions = container.querySelectorAll<HTMLInputElement>('.js-suggested-username')
  const form = document.querySelector('.js-signup-form')

  if (!form || suggestions.length === 0) {
    return
  }

  for (const suggestion of suggestions) {
    form.appendChild(suggestion)
  }
})

observe('.js-octocaptcha-parent', function (form) {
  const spinner = form.querySelector<HTMLElement>('.js-octocaptcha-spinner')!
  const success = form.querySelector<HTMLElement>('.js-octocaptcha-success')!
  const input = form.querySelector<HTMLInputElement>('.js-octocaptcha-token')!
  const formSubmit = form.querySelector<HTMLButtonElement>('.js-octocaptcha-form-submit')!
  const iframe = form.querySelector<HTMLIFrameElement>('.js-octocaptcha-frame')!
  const octocaptchaUrl = input.getAttribute('data-octocaptcha-url')
  const dataCaptchaTimeout = input.getAttribute('data-octocaptcha-timeout')
  const captchaTimeout = dataCaptchaTimeout ? parseInt(dataCaptchaTimeout) : 30000

  let loaded = false

  const showSuccess = () => {
    if (loaded) return
    loaded = true
    /* eslint-disable-next-line github/no-d-none */
    spinner.classList.add('d-none')
    /* eslint-disable-next-line github/no-d-none */
    success.classList.remove('d-none')
    formSubmit.disabled = false
    formSubmit.hidden = false
  }

  const showCaptcha = (height: number, width: number) => {
    if (loaded) return
    loaded = true
    /* eslint-disable-next-line github/no-d-none */
    spinner.classList.add('d-none')
    iframe.classList.remove('v-hidden')
    iframe.style.height = `${height}px`
    iframe.style.width = `${width}px`
    iframe.contentWindow?.postMessage({event: 'captcha-loaded-ack'}, octocaptchaUrl || '')
  }

  const showFailedToLoadSuccess = () => {
    if (loaded) return
    const hiddenInput = document.createElement('input')
    hiddenInput.type = 'hidden'
    hiddenInput.id = 'error_loading_captcha'
    hiddenInput.name = 'error_loading_captcha'
    hiddenInput.value = '1'

    form.appendChild(hiddenInput)
    input.required = false

    showSuccess()
  }

  const captchaComplete = () => {
    formSubmit.hidden = false
    if ((form as HTMLFormElement).checkValidity()) {
      formSubmit.disabled = false
    }
  }

  // If captcha fails to load, let the user through
  setTimeout(showFailedToLoadSuccess, captchaTimeout)
  iframe.addEventListener('error', showFailedToLoadSuccess)

  window.addEventListener('message', e => {
    if (e.origin !== octocaptchaUrl) return

    const event = e.data && e.data.event

    if (event === 'captcha-loaded') {
      const height = e.data.height || 380
      const width = e.data.width || 654
      showCaptcha(height, width)
    } else if (event === 'captcha-complete') {
      input.value = e.data.sessionToken
      captchaComplete()
    } else if (event === 'captcha-suppressed') {
      showSuccess()
    }
  })
})

observe('.js-survey-answer-choice:checked', {
  add(input) {
    const answer = input.closest('.js-answer')
    if (answer) {
      const answerChoice = answer.querySelector('.js-answer-choice')
      if (answerChoice) {
        answerChoice.classList.remove('color-border-subtle', 'color-bg-default')
        answerChoice.classList.add('color-border-accent-emphasis', 'color-bg-accent')
      }
    }

    const otherRoleInput = document.querySelector(
      `.js-other-input-box[data-other-input-for=${input.getAttribute('data-question-short-text')}]`,
    )

    if (otherRoleInput instanceof HTMLElement && input.classList.contains('js-other-choice')) {
      otherRoleInput.hidden = false
    }
  },
  remove(input) {
    const answer = input.closest('.js-answer')
    if (answer) {
      const answerChoice = answer.querySelector('.js-answer-choice')
      if (answerChoice) {
        answerChoice.classList.remove('color-border-accent-emphasis', 'color-bg-accent')
        answerChoice.classList.add('color-border-subtle', 'color-bg-default')
      }
    }

    const otherRoleInput = document.querySelector(
      `.js-other-input-box[data-other-input-for=${input.getAttribute('data-question-short-text')}]`,
    )

    if (otherRoleInput instanceof HTMLElement && input.classList.contains('js-other-choice')) {
      otherRoleInput.hidden = true
    }
  },
})

observe('.js-allow-multiple:checked', {
  constructor: HTMLInputElement,
  add(input) {
    const maximumAllowedAnswers = parseInt(input.getAttribute('data-max-choices') || '')

    const parentQuestion = input.closest<HTMLElement>('.js-question')!
    const allAnswers = parentQuestion.querySelectorAll<HTMLInputElement>('.js-allow-multiple')
    const selectedAnswerCount = Array.from(allAnswers).filter(el => el.checked === true).length

    // Disable a question's unchecked answers when three or more are selected
    if (selectedAnswerCount >= maximumAllowedAnswers) {
      for (const answer of allAnswers) {
        if (answer.checked === false) {
          answer.disabled = true
        }
      }
    }
  },
  remove(input) {
    const parentQuestion = input.closest<HTMLElement>('.js-question')!

    for (const answer of parentQuestion.querySelectorAll<HTMLInputElement>('.js-allow-multiple')) {
      answer.disabled = false
    }
  },
})

function validatePasswordConfirmation() {
  const passwordField = document.querySelector<HTMLInputElement>('.js-password-with-confirmation')!
  const passwordConfirmField = document.querySelector<HTMLInputElement>('.js-password-confirm')!

  if (passwordConfirmField.value !== passwordField.value) {
    showPasswordValidationError(passwordConfirmField)
  } else {
    hidePasswordValidationError(passwordConfirmField)
  }
}

function showPasswordValidationError(passwordConfirmField: HTMLInputElement) {
  const passwordConfirmFormGroup = passwordConfirmField.closest<HTMLElement>('.js-form-group')!
  passwordConfirmFormGroup.classList.add('errored')

  const validityMessage = passwordConfirmField.getAttribute('data-validity-message')
  if (validityMessage) {
    passwordConfirmField.setCustomValidity(validityMessage)
    const form = passwordConfirmField.closest<HTMLFormElement>('form')!
    validate(form)
  }

  const errorNode = passwordConfirmFormGroup.querySelector('.error')
  const errorText = passwordConfirmField.getAttribute('data-error-message')
  if (!errorNode && errorText) {
    const newErrorNode = document.createElement('dd')
    newErrorNode.classList.add('error')
    newErrorNode.textContent = errorText
    passwordConfirmFormGroup.appendChild(newErrorNode)
  }
}

function hidePasswordValidationError(passwordConfirmField: HTMLInputElement) {
  const passwordConfirmFormGroup = passwordConfirmField.closest<HTMLElement>('.js-form-group')!
  passwordConfirmField.setCustomValidity('')
  passwordConfirmFormGroup.classList.remove('errored')
  const form = passwordConfirmField.closest<HTMLFormElement>('form')!
  validate(form)

  const errorNode = passwordConfirmFormGroup.querySelector('.error')
  if (errorNode) {
    passwordConfirmFormGroup.removeChild(errorNode)
  }
}

// Keep the row selection in sync with the checkbox values
observe('.js-plan-choice:checked', {
  add(el) {
    const row = el.closest('.plan-row')
    if (row) {
      row.classList.add('selected')
    }
  },
  remove(el) {
    const row = el.closest('.plan-row')
    if (row) {
      row.classList.remove('selected')
    }
  },
})

// Toggle nested checkbox content, if checked
observe('input.js-form-checked-trigger:checked', {
  constructor: HTMLInputElement,
  add(input) {
    const container = input.closest<HTMLElement>('.js-form-checked-container')!
    const target = container.querySelector<HTMLElement>('.js-form-checked-target')!
    target.hidden = false
  },
  remove(input) {
    const container = input.closest<HTMLElement>('.js-form-checked-container')!
    const target = container.querySelector<HTMLElement>('.js-form-checked-target')!
    const targetInput = target.querySelector<HTMLInputElement>('input')!
    changeValue(targetInput, false)
    target.hidden = true
  },
})

// When a required radio button is checked, enable the submit button
observe(
  '.js-form-radio-button-required input[type="radio"]:checked, input[type="radio"]:checked.js-form-radio-button-required',
  {
    constructor: HTMLInputElement,
    add(input) {
      enableSubmitButton(input)
    },
  },
)

function enableSubmitButton(input: HTMLInputElement) {
  const submitButton = input.closest<HTMLElement>('form')?.querySelector('button')

  submitButton!.disabled = false
}
